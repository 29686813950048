import { Box, Image } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Register } from "./components/Register";
import { Chat } from "./components/Chat";
import { ResponseAddConversation } from "./core/models";

export const HomePage = () => {
  const [openChat, setOpenChat] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const [isChat, setIsChat] = useState(false);
  const [conversation, setConversation] = useState<ResponseAddConversation>();

  useEffect(() => {
    localStorage.clear();
  }, []);

  const openChatHandler = () => {
    setOpenChat(!openChat);
    if (!isChat) {
      setIsRegister(true);
    } else {
      setIsChat(true);
    }
  };

  const startChatHandler = (data: ResponseAddConversation) => {
    setConversation(data);
    setIsRegister(false);
    setIsChat(!isChat);
  };

  const onLogout = () => {
    setIsRegister(true);
    setIsChat(false);
  };

  return (
    <>
      {/* <Box
        minH="100vh"
        bgImage="./icons/bg_jepara.png"
        backgroundPosition="center"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
      ></Box> */}
      <Box
        minH="100vh"
        bgImage="./icons/bg_kudus.png"
        backgroundPosition="top"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
      ></Box>
      <Image
        src="./icons/cs_icon.svg"
        alt="Customer Service"
        pos="absolute"
        bottom="2rem"
        right={openChat ? "28rem" : "3rem"}
        cursor="pointer"
        onClick={openChatHandler}
      />
      {openChat && isRegister && (
        <Register onClose={openChatHandler} onStartChat={startChatHandler} />
      )}
      {openChat && isChat && (
        <Chat onLogout={onLogout} conversation={conversation} />
      )}
    </>
  );
};
