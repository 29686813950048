import {
  Badge,
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";

import { MdSend } from "react-icons/md";

import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { FC, useEffect, useState } from "react";
import {
  RequestAddLogConversation,
  RequestSendMessage,
  ResponseAddConversation,
} from "../core/models";
import { addLogConversation, logout, sendMessage } from "../core/API";
import { AxiosError } from "axios";
import { Response, SweetAlertError } from "../../../helpers";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  Unsubscribe,
  where,
} from "firebase/firestore";
import { db } from "../../../helpers/firebaseClient";
import moment from "moment";

type Props = {
  onLogout: () => void;
  conversation: ResponseAddConversation | undefined;
};

const SHOW_MODAL_IN_MINUTES = 10;
const END_MODAL_IN_MINUTES = 5;

// DEBUG
// const SHOW_MODAL_IN_MINUTES = 1;
// const END_MODAL_IN_MINUTES = 1;
let interval: NodeJS.Timeout;

const Chat: FC<Props> = ({ onLogout, conversation }) => {
  // const isMounted = useRef(false);
  const modalEndChat = useDisclosure();
  const modalCountDown = useDisclosure();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const [chats, setChats] = useState<any[]>([]);
  const [chat, setChat] = useState("");
  const [countdown, setCountdown] = useState("");

  const scrollToBottomWithSmoothScroll = () => {
    let containerChat = document.getElementById("container-chat");
    containerChat?.scrollTo({
      top: containerChat.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    // if (isMounted.current) {
      const q = query(
        collection(db, "messages"),
        where("conversation_id", "==", conversation?.conversation_id),
        orderBy("created_at", "asc")
      );
      unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          clearInterval(interval);
          const chatList: any[] = [];
          snapshot.forEach((doc) => {
            chatList.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          setChats(chatList);
          interval = timer();
        },
        (error) => {
          console.log(error);
        }
      );
    // }
    return () => {
      // if (isMounted.current) {
        unsubscribe();
        clearInterval(interval);
      // } else {
      //   isMounted.current = true;
      // }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const timer = () => {
    const lastUpdate = moment().valueOf();
    const limitTime = moment(lastUpdate)
      .add(SHOW_MODAL_IN_MINUTES + END_MODAL_IN_MINUTES, "minutes")
      .valueOf();
    return setInterval(() => {
      const minutes = moment.duration(moment().diff(lastUpdate)).asMinutes();
      if (minutes >= SHOW_MODAL_IN_MINUTES) {
        showModalCountDown();
        const times = moment.duration(limitTime - moment.now().valueOf());
        if (minutes >= SHOW_MODAL_IN_MINUTES + END_MODAL_IN_MINUTES) {
          clearInterval(interval);
          endChat();
        } else {
          setCountdown(
            `${times.minutes() < 10 ? "0" + times.minutes() : times.minutes()}:${
              times.seconds() < 10 ? "0" + times.seconds() : times.seconds()
            }`
          );
        }
      }
    }, 990);
  };

  const replaceURLWithHTMLLinks = (text: string) => {
    const exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.replace(exp,"<a href='$1' target='_blank' style='color: blue;'>$1</a>"); 
  }

  useEffect(() => {
    scrollToBottomWithSmoothScroll();
  }, [chats]);

  const chatHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setChat(e.currentTarget.value);
  };

  const onSubmit: SubmitHandler<FieldValues> = async (param) => {
    if (chat.trim().length === 0) return;
    try {
      const data: RequestSendMessage = {
        conversation_id: conversation?.conversation_id + "",
        message: chat.trim(),
        message_type: 0,
        reply: null,
        url: "",
        userID: conversation?.user_id + "",
      };
      await sendMessage(data);
      setChat("");
    } catch (error) {
      const err = error as AxiosError<Response<null>>;
      SweetAlertError(err.response?.data?.msg);
    }
  };

  const showModalEndChat = () => {
    modalEndChat?.onOpen();
  };

  const showModalCountDown = () => {
    modalCountDown?.onOpen();
  };

  const endChat = async () => {
    try {
      const param: RequestAddLogConversation = {
        conversation_id: conversation?.conversation_id ?? "",
      };
      await addLogConversation(param);
      await logout();
      localStorage.clear();
      onLogout();
    } catch (error) {
      const err = error as AxiosError<Response<null>>;
      SweetAlertError(err.response?.data?.msg);
    }
  };

  const continueChat = () => {
    clearInterval(interval);
    modalCountDown?.onClose();
    interval = timer();
  };

  return (
    <Box
      pos="absolute"
      bottom="2rem"
      right="3rem"
      borderRadius="xl"
      border="1px"
      borderColor="gray.200"
      bgColor="#FFFFFF"
      h="30rem"
      w="23rem"
    >
      <HStack p="5">
        <Image src="./icons/avatar.svg" alt="Avatar" mr="1" />
        <Heading size="md" flex="1">
          Admin Service
        </Heading>
        <VStack
          cursor="pointer"
          h="5"
          justifyContent="center"
          onClick={showModalEndChat}
        >
          <Image src="./icons/close.svg" alt="Close" />
        </VStack>
      </HStack>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: "83%" }}>
        <VStack p="5" align="stretch" spacing="3" h="full">
          <HStack justifyContent="center">
            <Badge
              background="#E5E7F4"
              borderRadius="xl"
              px="1rem"
              py="0.3rem"
              textTransform="capitalize"
            >
              {moment().locale("id").format("DD MMMM YYYY")}
            </Badge>
          </HStack>
          <Box overflowX="auto" id="container-chat">
            <HStack mt="1rem">
              <Heading size="xs" color="#3641B7">
                Admin Service
              </Heading>
              <Text fontSize="xs" color="#B5B5B5">
                {localStorage.timeAdminService}
              </Text>
            </HStack>
            <Text fontSize="xs" mt="1">
              Halo Sahabat ada yang bisa dibantu?
            </Text>
            {chats.map((data) => (
              <Box key={data?.id} mr="1rem">
                {data.sender_user_id === conversation?.user_id ? (
                  <>
                    <HStack mt="1rem" justifyContent="flex-end">
                      <Text fontSize="xs" color="#B5B5B5">
                        {moment.unix(data.updated_at / 1000).format("HH:mm")}
                      </Text>
                      <Heading size="xs" color="#000000">
                        {conversation?.title}
                      </Heading>
                    </HStack>
                    <Text
                      fontSize="xs"
                      mt="1"
                      textAlign="right"
                      color="#000000"
                      fontWeight="500"
                      whiteSpace="pre-line"
                      dangerouslySetInnerHTML={{ __html: replaceURLWithHTMLLinks(data.message) }}
                    >
                    </Text>
                  </>
                ) : (
                  <>
                    <HStack mt="1rem">
                      <Heading size="xs" color="#3641B7">
                        Admin Service
                      </Heading>
                      <Text fontSize="xs" color="#B5B5B5">
                        {moment.unix(data.updated_at / 1000).format("HH:mm")}
                      </Text>
                    </HStack>
                    <Text
                      fontSize="xs"
                      mt="1"
                      color="#000000"
                      fontWeight="500"
                      whiteSpace="pre"
                      dangerouslySetInnerHTML={{ __html: replaceURLWithHTMLLinks(data.message) }}
                    >
                    </Text>
                  </>
                )}
              </Box>
            ))}
          </Box>
          <VStack flex="1" justifyContent="flex-end">
            <HStack w="full">
              <FormControl isInvalid={errors.chat}>
                <Textarea
                  id="chat"
                  placeholder="Tulis pesanmu disini"
                  value={chat}
                  fontSize="xs"
                  {...register("chat", {
                    required: "Tidak Boleh Kosong!",
                    onChange: chatHandler,
                  })}
                />
                <FormErrorMessage mt="0.5">
                  {errors.chat && errors.chat.message}
                </FormErrorMessage>
              </FormControl>
              <IconButton
                aria-label="Send"
                borderRadius="50%"
                icon={<MdSend />}
                bg={"#FFC140"}
                color={"white"}
                w="1rem"
                _hover={{
                  bg: "#FFC140AA",
                }}
                isLoading={isSubmitting}
                type="submit"
              />
            </HStack>
          </VStack>
        </VStack>
      </form>
      <Modal
        closeOnOverlayClick={false}
        onClose={modalEndChat?.onClose}
        size={"md"}
        isOpen={modalEndChat?.isOpen}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ fontWeight: "bold" }}>
            <Text>Akhiri Perbincangan?</Text>
            <Divider mt="2" />
          </ModalHeader>
          {/* <modalEndChatButton /> */}
          <ModalBody>
            <Text fontWeight="500">
              Perbincangan yang diakhiri akan terhapus, apakah Anda yakin ingin
              mengakhiri perbincangan?
            </Text>
          </ModalBody>
          <ModalFooter>
            <VStack w="full" spacing="4">
              <Divider />
              <HStack w="full">
                <Button
                  flex="0.5"
                  onClick={modalEndChat?.onClose}
                  border="1px"
                  borderColor="#F1416C"
                  color="#F1416C"
                  backgroundColor="#FFFFFF"
                >
                  Batal
                </Button>
                <Button
                  flex="0.5"
                  onClick={endChat}
                  color="#FFFFFF"
                  backgroundColor="#F1416C"
                >
                  Akhiri
                </Button>
              </HStack>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        closeOnOverlayClick={false}
        onClose={modalCountDown?.onClose}
        size={"md"}
        isOpen={modalCountDown?.isOpen}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ fontWeight: "bold" }}>
            <Text>Lanjut Berbincang?</Text>
            <Divider mt="2" />
          </ModalHeader>
          <ModalBody>
            <Text
              color="#F1416C"
              textAlign="center"
              fontWeight="bold"
              fontSize="2xl"
              mb="1rem"
            >
              {countdown}
            </Text>
            <Text fontWeight="500">
              Halo! Apakah Kamu masih ada pertanyaan lagi?
              <br />
              Pilih Lanjutkan untuk kembali berbincang.
            </Text>
          </ModalBody>
          <ModalFooter>
            <VStack w="full" spacing="4">
              <Divider />
              <HStack w="full">
                <Button
                  flex="0.5"
                  onClick={endChat}
                  border="1px"
                  borderColor="#F1416C"
                  color="#F1416C"
                  backgroundColor="#FFFFFF"
                >
                  Akhiri
                </Button>
                <Button
                  flex="0.5"
                  onClick={continueChat}
                  color="#FFFFFF"
                  backgroundColor="#F1416C"
                >
                  Lanjutkan
                </Button>
              </HStack>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export { Chat };
