import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  VStack,
} from "@chakra-ui/react";

import { MdPerson, MdPhone, MdEmail } from "react-icons/md";

import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { FC, useState } from "react";

import { createConversation } from "../core/API";
import {
  RequestAddConversation,
  ResponseAddConversation,
} from "../core/models";
import { SweetAlertError, Response } from "../../../helpers";
import { AxiosError } from "axios";
import moment from "moment";

type Props = {
  onClose: () => void;
  onStartChat: (data: ResponseAddConversation) => void;
};

const Register: FC<Props> = ({ onClose, onStartChat }) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const nameHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
  };

  const phoneHandler = (e: React.FormEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/;
    if (e.currentTarget.value === "" || re.test(e.currentTarget.value)) {
      setPhone(e.currentTarget.value);
    }
  };

  const emailHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  };

  const onSubmit: SubmitHandler<FieldValues> = async (param) => {
    try {
      const data: RequestAddConversation = {
        name: name.trim(),
        phone_no: phone.trim(),
        email: email.trim(),
        company_id: "mYoOeGMqYLg58oWTwHbe",
      };
      const response = await createConversation(data);
      localStorage.setItem("token", response?.token ?? "");
      localStorage.setItem("timeAdminService", moment().locale("id").format("HH:mm") ?? "");
      onStartChat(response as ResponseAddConversation);
    } catch (error) {
      const err = error as AxiosError<Response<null>>;
      SweetAlertError(err.response?.data?.msg);
    }
  };

  return (
    <Box
      pos="absolute"
      bottom="2rem"
      right="3rem"
      borderRadius="xl"
      border="1px"
      borderColor="gray.200"
      bgColor="#FFFFFF"
      h="30rem"
      w="23rem"
    >
      <HStack p="5">
        <Image src="./icons/avatar.svg" alt="Avatar" mr="1" />
        <Heading size="md" flex="1">
          Admin Service
        </Heading>
        <VStack
          cursor="pointer"
          h="5"
          justifyContent="center"
          onClick={onClose}
        >
          <Image src="./icons/close.svg" alt="Close" />
        </VStack>
      </HStack>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: "83%" }}>
        <VStack p="5" align="stretch" spacing="3" h="full">
          <Box>
            <HStack>
              <Heading size="xs" color="#3641B7">
                Admin Service
              </Heading>
              {/* <Text fontSize="xs" color="#B5B5B5">
                16:02
              </Text> */}
            </HStack>
            <Text fontSize="xs" mt="1">
              Halo! Saya disini untuk membantu Kamu.
              <br />
              Boleh tahu Saya berbicara dengan siapa?
            </Text>
          </Box>
          <FormControl isInvalid={errors.name}>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<MdPerson color="#A0AEC0" />}
              />
              <Input
                id="name"
                type="text"
                placeholder="Nama Lengkap"
                value={name}
                {...register("name", {
                  required: "Tidak Boleh Kosong!",
                  onChange: nameHandler,
                })}
              />
            </InputGroup>
            <FormErrorMessage mt="0.5">
              {errors.phone && errors.phone.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.phone}>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<MdPhone color="#A0AEC0" />}
              />
              <Input
                id="phone"
                type="tel"
                placeholder="Nomor Telepon"
                value={phone}
                {...register("phone", {
                  required: "Tidak Boleh Kosong!",
                  onChange: phoneHandler,
                })}
              />
            </InputGroup>
            <FormErrorMessage mt="0.5">
              {errors.phone && errors.phone.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.email}>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<MdEmail color="#A0AEC0" />}
              />
              <Input
                id="email"
                type="text"
                placeholder="Email"
                value={email}
                {...register("email", {
                  // required: "Tidak Boleh Kosong!",
                  onChange: emailHandler,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Format Email Salah!",
                  },
                })}
              />
            </InputGroup>
            <FormErrorMessage mt="0.5">
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>
          <VStack flex="1" justifyContent="flex-end">
            <Button
              bg={"#FFC140"}
              color={"white"}
              w="full"
              _hover={{
                bg: "#FFC140EE",
              }}
              isLoading={isSubmitting}
              type="submit"
            >
              Mulai Chat
            </Button>
          </VStack>
        </VStack>
      </form>
    </Box>
  );
};

export { Register };
