import Swal from "sweetalert2";

export const SweetAlertSuccess = (bodyMessage: string = "", title?: string) => {
  Swal.fire({
    heightAuto: false,
    allowOutsideClick: false,
    title: title ?? "Success",
    text: bodyMessage,
    icon: "success",
    confirmButtonColor: "#00A3FF",
    confirmButtonText: "Ok",
  });
};

export const SweetAlertError = (bodyMessage: string = "", title?: string) => {
  Swal.fire({
    heightAuto: false,
    allowOutsideClick: false,
    title: title ?? "Error",
    text: bodyMessage,
    icon: "error",
    confirmButtonColor: "#00A3FF",
    confirmButtonText: "Ok",
  });
};
