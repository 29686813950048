import { AxiosResponse } from "axios";
import { axiosClient, Response } from "../../../helpers";
import {
  RequestAddConversation,
  RequestAddLogConversation,
  RequestSendMessage,
  ResponseAddConversation,
  ResponseSendMessage,
} from "./models";

const createConversation = (
  param: RequestAddConversation
): Promise<ResponseAddConversation | undefined> => {
  return axiosClient
    .post(`/v1/account/conversation/web`, param)
    .then(
      (response: AxiosResponse<Response<ResponseAddConversation>>) =>
        response.data
    )
    .then((response: Response<ResponseAddConversation>) => response.data);
};

const sendMessage = (
  param: RequestSendMessage
): Promise<ResponseSendMessage | undefined> => {
  axiosClient.defaults.headers.common["Authorization"] = localStorage.token
    ? "Bearer " + localStorage.token
    : "";
  return axiosClient
    .post(`/v1/account/message`, param)
    .then(
      (response: AxiosResponse<Response<ResponseSendMessage>>) => response.data
    )
    .then((response: Response<ResponseSendMessage>) => response.data);
};

const addLogConversation = (
  param: RequestAddLogConversation
): Promise<undefined> => {
  axiosClient.defaults.headers.common["Authorization"] = localStorage.token
    ? "Bearer " + localStorage.token
    : "";
  return axiosClient
    .post(`/v1/account/dashboard`, param)
    .then((response: AxiosResponse<Response<undefined>>) => response.data)
    .then((response: Response<undefined>) => response.data);
};

const logout = (): Promise<undefined> => {
  axiosClient.defaults.headers.common["Authorization"] = localStorage.token
    ? "Bearer " + localStorage.token
    : "";
  return axiosClient
    .post(`/v1/account/admin-web/logout`)
    .then((response: AxiosResponse<Response<undefined>>) => response.data)
    .then((response: Response<undefined>) => response.data);
};

export { createConversation, sendMessage, addLogConversation, logout };
